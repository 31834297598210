import { ConfigProductsProps } from './types'

const CDI = 0.044
const SELIC_DIARIA = 0.044

const SELIC_META = 0.045
const RATE_POUPANCA =
  SELIC_META > 0.085 ? Math.pow(1 + 0.005, 12) : SELIC_META * 0.7

export const configProducts: ConfigProductsProps = {
  lci: {
    color: '#e83e8c',
    class: 'pink',
    title: 'LCI',
    rate: CDI * 1,
    grossUp: (CDI * 1) / 0.85,
  },
  cdb: {
    color: '#00BFFB',
    class: 'blue',
    title: 'CDB',
    rate: CDI * 1.1,
    grossUp: CDI * 1.1,
  },
  poupanca: {
    color: '#6c757d',
    class: 'gray',
    title: 'Poupança',
    rate: RATE_POUPANCA,
    grossUp: RATE_POUPANCA / 0.85,
  },
  tesouro: {
    color: '#ffc107',
    class: 'yellow',
    title: 'Tesouro Direto',
    rate: SELIC_DIARIA * 1,
    grossUp: SELIC_DIARIA * 1,
  },
}
