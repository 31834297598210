import { gray, pink } from 'src/styles/colors'
import styled from 'styled-components'

export const FixedIncomeWrapper = styled.div`
  .product {
    height: 14px;
    width: 14px;
    border-radius: 4px;
    margin-right: 5px;
    display: inline-block;

    &.blue {
      background-color: #00bffb;
    }

    &.pink {
      background-color: ${pink};
    }

    &.yellow {
      background-color: #ffc107;
    }

    &.gray {
      background-color: ${gray[600]};
    }
  }

  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: transparent;
    z-index: 2;
  }
`

export const Tooltip = styled.div`
  min-width: 145px;
  li:first-child {
    &::before {
      content: "";
      display: inline-block;
      height: 15px;
      width: 15px;
      margin-right: 8px;
      background-image: url("../../../../assets/images/svg/investment-star.svg");
    }
    p:first-child {
      display: inline-block;
    }
  }

  li p {
    line-height: initial;
  }

  li p.cdb {
    color: #00bffb;
  }

  li p.lci {
    color: ${pink};
  }

  li p.tesouro {
    color: #ffc107;
  }

  li p.poupanca {
    color: ${gray[800]};
  }
`
