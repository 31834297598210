import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'

import MarkdownIcon from 'src/components/UI/MarkdownIcon'

import { configProducts } from './configProducts'
import { CustomTooltip } from './CustomTooltip'
import { deadlines } from './deadlines'
import { DataChartProps, DeadlineProps } from './types'
import { FixedIncomeWrapper } from './styles'

type FixeIncomeProps = {
  products?: string;
};

export const FixedIncome = ({
  products = 'cdb,lci,tesouro,poupanca',
}: FixeIncomeProps) => {
  const [ dataChart, setDataChart ] = useState<DataChartProps[]>([])
  const [ initialInvestment, setInitialInvestment ] = useState(0)
  const [ activeDeadline, setActiveDeadline ] = useState(0)
  const [ investmentAxis, setInvestmentAxis ] = useState([ 1000, 1080 ])
  const [ periodAxis, setPeriodAxis ] = useState([
    0,
    30,
    60,
    90,
    120,
    150,
    180,
    240,
    300,
    360,
  ])
  const listProducts = products.split(',')

  useEffect(() => {
    const newDataChart: DataChartProps[] = []
    periodAxis.forEach((period: number) => {
      newDataChart.push({
        period: period,
        investment: investmentAxis,
        lci:
          investmentAxis[0] *
          Math.pow(1 + configProducts.lci.grossUp, period / 360),
        cdb:
          investmentAxis[0] *
          Math.pow(1 + configProducts.cdb.grossUp, period / 360),
        poupanca:
          investmentAxis[0] *
          Math.pow(1 + configProducts.poupanca.grossUp, period / 360),
        tesouro:
          investmentAxis[0] *
          Math.pow(1 + configProducts.tesouro.grossUp, period / 360),
      })
    })
    setDataChart(newDataChart)
  }, [ initialInvestment, activeDeadline ])

  const changeInvestment = (value: string) => {
    setInitialInvestment(parseInt(value))
    setInvestmentAxis(deadlines[activeDeadline].investment[parseInt(value)])
  }

  const changePeriod = (deadline: DeadlineProps, index: number) => {
    setActiveDeadline(index)
    setPeriodAxis(deadline.period)
    setInvestmentAxis(deadlines[index].investment[initialInvestment])
  }

  return (
    <FixedIncomeWrapper className='bg-white p-2'>
      <header className='d-flex flex-wrap'>
        <div className='flex-fill'>
          <p className='text-gray--light fs-14 mb-1'>Investimento Inicial</p>
          <div className='bg-white position-relative border rounded-3 w-100 px-2'>
            <select
              className='select position-absolute fs-16 fw-400 text-gray--base border-0 h-100 w-100'
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                changeInvestment(e.currentTarget.value)
              }
              aria-label='Investimento Inicial'
            >
              <option value='0'>R$ 1.000,00</option>
              <option value='1'>R$ 5.000,00</option>
              <option value='2'>R$ 10.000,00</option>
            </select>
            <span className='d-block text-right'>
              <MarkdownIcon
                color='gray'
                width='15'
                height='15'
                icon='navigation/chevron-down'
                directory='v2'
              />
            </span>
          </div>
        </div>
        <div className='flex-fill mx-2'>
          <p className='text-gray--light fs-14 mb-1'>Período</p>
          <div className='bg-gray text-orange--base text-center rounded-3 p-1 fs-16 fw-400'>
            {
              deadlines[activeDeadline].period[
                deadlines[activeDeadline].period.length - 1
              ]
            }{' '}
            dias
          </div>
        </div>
        <div className='flex-fill'>
          <p className='text-gray--light fs-14 mb-1'>Produtos</p>
          <ul className='list-unstyled d-flex flex-wrap align-items-center justify-content-between py-1 px-3 rounded-3 border'>
            {listProducts.map((product: string, index: number) => (
              <li key={index} className='d-flex align-items-center mx-1'>
                <span className={`product ${configProducts[product].class}`} />
                <p className='text-gray--light fs-14 m-0'>
                  {configProducts[product].title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </header>

      <div className='border mt-3 rounded-3'>
        <ResponsiveContainer width='100%' height={245}>
          <LineChart
            data={dataChart}
            margin={{ top: 20, right: 20, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeWidth={0.5} />
            <YAxis
              dataKey='investment'
              domain={[ 'dataMin', 'dataMax' ]}
              tick={{ fontSize: 12 }}
            />
            <XAxis dataKey='period' tick={{ fontSize: 12 }} />
            <Tooltip content={<CustomTooltip />} />
            {listProducts.map((product: string, index: number) => (
              <Line
                key={index}
                dot={false}
                activeDot={{
                  stroke: configProducts[product].color,
                  strokeWidth: 4,
                  r: 5,
                  fill: '#ffffff',
                }}
                type='monotone'
                dataKey={product}
                stroke={configProducts[product].color}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      <ul className='row list-unstyled mt-3'>
        {deadlines.map((deadline: DeadlineProps, index: number) => (
          <li key={index} className='col'>
            <button
              className={`btn text-capitalize rounded-3 p-md-2 fs-14 text-gray--base ${
                activeDeadline === index ? 'btn--outline btn--orange' : 'border'
              }`}
              onClick={() => {
                changePeriod(deadline, index)
              }}
            >
              {deadline.title}
            </button>
          </li>
        ))}
      </ul>
    </FixedIncomeWrapper>
  )
}
