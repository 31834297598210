import React from 'react'
import { Utility } from 'inter-site-components'
import { TooltipProps, TooltipPayload } from 'recharts'

import { Tooltip } from './styles'

export const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
  const payloads = payload as TooltipPayload[]

  if (active && payloads && label) {
    payloads.sort((prev: TooltipPayload, next: TooltipPayload) =>
      prev.value < next.value ? 1 : next.value < prev.value ? -1 : 0,
    )

    return (
      <Tooltip className='bg-white py-2 px-3 rounded-3 shadow-sm'>
        <ul className='list-unstyled'>
          {payloads.map((item: TooltipPayload, i: number) => (
            <li key={i}>
              <p className={`fs-16 fw-400 mb-0 text-right ${item.name}`}>
                R$ {Utility.numberToReal(item.value as number)}
              </p>
              <p className='fs-14 fw-400 mb-2 text-gray--light text-uppercase text-right'>
                {item.name}
              </p>
            </li>
          ))}
        </ul>
      </Tooltip>
    )
  }

  return null
}
