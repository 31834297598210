import { DeadlineProps } from './types'

export const deadlines: DeadlineProps[] = [
  {
    title: 'Curto Prazo',
    investment: [
      [ 1000, 1080 ],
      [ 5000, 5400 ],
      [ 10000, 10800 ],
    ],
    period: [ 0, 30, 60, 90, 120, 150, 180, 240, 300, 360 ],
  },
  {
    title: 'Médio Prazo',
    investment: [
      [ 1000, 1200 ],
      [ 5000, 6000 ],
      [ 10000, 12000 ],
    ],
    period: [ 0, 30, 60, 90, 120, 150, 180, 240, 300, 360, 480, 600, 720, 900 ],
  },
  {
    title: 'Longo Prazo',
    investment: [
      [ 1000, 1450 ],
      [ 5000, 7200 ],
      [ 10000, 14400 ],
    ],
    period: [
      0,
      30,
      60,
      90,
      120,
      150,
      180,
      240,
      300,
      360,
      480,
      600,
      720,
      900,
      1200,
      1500,
      1800,
    ],
  },
]
